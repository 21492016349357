import React, { useState } from "react"
import Layout from "../layout"
import PageTitle from "../components/page-title"
import Panel from "../components/panel"
import PanelContent from "../components/panel/content"
import PanelTitle from "../components/panel/title"
import Seo from "../components/seo"
import meta from "../meta-constants"

function URLEncoder() {
  const { title, description } = meta.urlencoder
  const [formData, setFormData] = useState({
    encoded: "",
    decoded: "",
    component: false,
  })

  const onChange = e => {
    setFormData(formData => ({
      ...formData,
      [e.target.name]: e.target.value,
    }))
  }

  const encode = e => {
    e && e.preventDefault()
    let encodedStr = ""
    try {
      encodedStr = formData.component
        ? encodeURIComponent(formData.decoded)
        : encodeURI(formData.decoded)
    } catch (err) {
      encodedStr = "Failed to encode: " + err.message
    }
    setFormData(formData => ({
      ...formData,
      encoded: encodedStr,
    }))
  }

  const toggleComponent = e => {
    setFormData(formData => ({
      ...formData,
      component: !formData.component,
    }))
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <PageTitle titleText={title} />
      <Panel>
        <PanelTitle titleText="Convert Non ASCII URL characters to URL Friendly Characters" />
        <PanelContent>
          <form id="form">
            <label htmlFor="decoded">
              Enter text you would like to encode below and click Encode:
            </label>
            <input
              type="text"
              id="decoded"
              required="required"
              className="form-control"
              name="decoded"
              onChange={onChange}
              value={formData.decoded}
            />
            {/*A-Z a-z 0-9 - _ . ! ~ * ' ( ) */}
            {/**A-Z a-z 0-9 ; , / ? : @ & = + $ - _ . ! ~ * ' ( ) # */}
            <br />
            <p>By default, following characters are not escaped.</p>
            <pre>; , / ? : @ &amp; = + $ #</pre>
            <input
              type="checkbox"
              name="component"
              id="component"
              onChange={toggleComponent}
            />{" "}
            <label htmlFor="component">
              Select checkbox to encode above characters as well
            </label>
            <br />
            <span
              tabIndex="0"
              role="button"
              className="btn btn-primary"
              onClick={encode}
              onKeyDown={encode}
            >
              Encode
            </span>
            <br />
            <br />
            <label htmlFor="encoded">
              Encoded text will be shown in below text box :
            </label>
            <input
              type="text"
              id="encoded"
              required="required"
              className="form-control"
              name="encoded"
              onChange={onChange}
              value={formData.encoded}
            />
          </form>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"About " + title} />
        <PanelContent>
          <p>{description}</p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText="What is URL Encoding?" />
        <PanelContent>
          <p>
            Percent-encoding, also known as URL encoding, is a method to encode
            arbitrary data in a Uniform Resource Identifier (URI) using only the
            limited US-ASCII characters legal within a URI. Although it is known
            as URL encoding, it is also used more generally within the main
            Uniform Resource Identifier (URI) set, which includes both Uniform
            Resource Locator (URL) and Uniform Resource Name (URN). As such, it
            is also used in the preparation of data of the
            /x-www-form-urlencoded media type, as is often used in the
            submission of HTML form data in HTTP requests.
          </p>
        </PanelContent>
      </Panel>
      <Panel>
        <PanelTitle titleText={"How to use " + title} />
        <PanelContent>
          <p>Use below steps to encode a URL:</p>
          <ol>
            <li>Open URL Encoder Online Tool</li>
            <li>Enter URL in first text box</li>
            <li>
              By default, certain characters are not escaped. If you want them
              to be escaped as well, click on the checkbox
            </li>
            <li>Click on Encode button</li>
            <li>Check the second text box for URL encoded output</li>
          </ol>
        </PanelContent>
      </Panel>
    </Layout>
  )
}

export default URLEncoder
